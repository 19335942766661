import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/common/views/src/MasonryLayout/MasonryLayout.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/feeds/views/src/BannerFeedLayout/BannerFeedLayout.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/feeds/views/src/ContentsFeedLayout/ContentsFeedLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BannerCarousel"] */ "/app/packages/contexts/home/src/features/components/BannerCarousel/BannerCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContentsFeedItem"] */ "/app/packages/contexts/home/src/features/components/ContentsFeed/ContentsFeedItem.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/home/src/features/components/Home/Home.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/home/src/features/components/HomeMainAppInstallLayer/HomeMainAppInstallLayer.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/home/src/features/components/MultiProductCardCarousel/MultiProductCardCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/home/src/features/components/ProductCardCarousel/ProductCardCarousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/home/src/features/components/ReadMoreSection/ReadMoreSection.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/home/src/views/Banner/Banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/contexts/home/src/views/Carousel/Carousel.tsx");
